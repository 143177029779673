<template lang="pug">
v-card(width="850px").pt-5.px-4
  v-card-title.mb-5.d-flex.justify-space-between
    span Импорт работников  
    v-btn(
      icon
      @click="$emit('cancel')"
    )
      v-icon fa-times
  v-card-text.pb-2
    OrganizationPicker(
      v-model="organizationId"
      :items="organizations"
      :error-messages="getValidationErrors('organizationId')"
      :disabled="$root.isDisabled('import_workers')"
      outlined
      dense
    )
    
    v-row
      v-col(cols="6")
        v-file-input(
          required
          v-model="file"
          :clearable="false"
          hide-details="auto"
          solo
          flat
          dense
          background-color="grey lighten-3"
          prepend-icon=""
          label="Добавить csv файл"
          :error-messages="getValidationErrors('file.name')"
          :disabled="$root.isDisabled('import_workers')"
        )
      v-col(cols="6")
        v-btn.caption(
          download="workersImportTemplate.csv"
          color="primary"
          :href="linkToImportTemplate"
          large
          block
          text
        )
          v-icon(color="blue" :style="{fontSize: '14px'}") fa-download
          v-col.d-flex.flex-column
            span.mb-1 Скачать шаблон
            span.text--disabled Импортируемого файла
    v-checkbox.mt-1(
      v-model="onlyCreate"
      label="Только создание работников."
    )
    v-tabs(
      v-model="tab"
      grow
      :show-arrows="false"
    )
      v-tab(v-for="tabItem in tabsContent" :key="tabItem.title") {{ tabItem.title }}

    div.result-container
      v-tabs-items.mb-0(
        v-model="tab"
      )
        v-tab-item(v-for="tabItem in tabsContent" :key="tabItem.title")
          v-list.mb-3

              template(v-if="tabItem.content.length > 0")
                v-list-group(
                  v-for="error in getErrors(tabItem.content)"
                  :key="error.text"
                  dense
                )
                  template(v-slot:appendIcon)
                    v-icon(:style="{fontSize: '12px'}") fa-chevron-down
                  template(v-slot:activator)
                    v-list-item.pa-0.ma-0(dense)
                      v-list-item-title.pa-0.ma-0 {{ error.text }}

                  v-list-item.ml-5(
                    v-for="(detailError, idx) in error.internal"
                    :key="idx"
                    dense
                  )
                    v-list-item-title.text-wrap.grey--text {{ `${idx + 1}. ${detailError.message} Код ошибки: ${detailError.code}.` }}

                p.ps-4(
                  v-for="line in getContentWithoutErrors(tabItem.content)"
                  :key="line.text"
                  :input-value="true"
                  dense
                ) {{ line.text }}

              template(v-else)
                spinner

    v-row(v-if="errorFile")
      v-col(cols="12")
        v-btn.caption(
          download="worker_errors.csv"
          color="primary"
          :href="errorFile"
          large
          block
          text
        )
          v-icon(color="blue" :style="{fontSize: '14px'}") fa-download
          v-col.d-flex.flex-column
            span.mb-1 Скачать файл c ошибками
            span.text--disabled Файл содержит не загруженных работников

    v-row
      v-col(cols="6")
        v-btn.text--disabled(
          outlined
          block
          :disabled="$root.isLoading('import_workers')"
          @click="$emit('cancel')"
        ) Отменить
      v-col(cols="6")
        v-btn.mb-2(
          depressed
          block
          :disabled="$root.isLoading('import_workers')"
          color="primary"
          @click="onSubmit"
        ) Импортировать


</template>

<script>
import api from '@/api';
import OrganizationPicker from '@/components/controls/OrganizationPicker.vue';

import mixins from '@/utils/mixins';
import Validate from '@/validations/workers-import';
import { mapGetters, mapActions } from 'vuex';

export default mixins(Validate).extend({
  components: {
    Spinner: () => import('@/components/Spinner.vue'),
    OrganizationPicker,
  },

  data() {
    return {
      errors: null,
      file: null,
      organizationId: null,
      onlyCreate: true,
      errorFile: null,
      tab: null,
      tabsContent: [
        {
          title: 'Формат импортируемого файла',
          content: [
            {
              text:
                '* Табельный номер - цифры или латинские буквы, от 4 до 30 символов, ' +
                'табельный номер должен быть уникальным в рамках организации. ' +
                'При загрузке файла система проверяет поле "табельный номер" на наличие в выбранной организации. ' +
                'В зависимости от того, будет ли найдено совпадение по табельному номеру среди существующих работников, ' +
                'система будет определять какое действие производить - заведение нового работника или обновление данных существующего.',
            },
            {
              text:
                '* Фамилия - текстовое поле (кириллица), минимальное количество символов - 2, ' +
                'двойные фамилии указываются через дефис без пробелов.',
            },
            {
              text:
                '* Имя - текстовое поле (кириллица), минимальное количество символов - 2, ' +
                'двойные имена указываются через дефис без пробелов.',
            },
            {
              text:
                'Отчество - текстовое поле (кириллица), ' +
                'двойные отчества указываются через дефис без пробелов.',
            },
            {
              text:
                '* Пол - указывается в числовом виде: 1 - мужской, 2 - женский.',
            },
            {
              text:
                '* Дата рождения - в формате ДД-ММ-ГГГГ, присутствует проверка на возраст ' +
                '(работнику должно быть минимум 18 полных лет).',
            },
            { text: 'Телефон - вносится в формате +7**********.' },
            {
              text:
                '№ водительского удостоверения - цифры и буквы, от 2 до 20 символов.',
            },
            {
              text:
                'Поля, отмеченные * обязательны к заполнению. Остальные поля можно оставить пустыми.',
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['organizations']),

    getErrors() {
      return content => {
        return content.filter(row => !!row.internal);
      };
    },

    getContentWithoutErrors() {
      return content => {
        return content.filter(row => !row.internal);
      };
    },

    linkToImportTemplate() {
      return api('employees').getLinkToImportTemplate();
    },
  },

  methods: {
    ...mapActions('EMPLOYEE', {
      import: 'import',
    }),

    async prepareResult(importedResult) {
      if (this.tabsContent.length === 1) {
        this.tabsContent.push({
          title: 'Результат импорта',
          content: [],
        });
      } else {
        this.tabsContent[1].content = [];
      }

      this.tab = 1;
      this.errorFile = null;

      try {
        const { created, updated, failed, url } = await importedResult;

        if (failed && failed.length > 0) {
          failed.forEach(error => {
            this.tabsContent[1].content.push({
              text: `Ошибка: строка ${error.row}`,
              internal: error.errors,
            });
          });
        }

        if (
          created.length === 0 &&
          updated.length === 0 &&
          failed.length === 0
        ) {
          this.tabsContent[1].content.push({
            text: 'Вы пытаетесь импортировать работников из пустого файла.',
          });
        }

        if (created) {
          const insertedCount = Array.isArray(created) ? created.length : 0;
          this.tabsContent[1].content.push({
            text: `Добавлено работников: ${insertedCount}`,
          });
        }

        if (updated) {
          this.tabsContent[1].content.push({
            text: `Обновлено работников: ${updated.length}`,
          });
        }

        if (failed) {
          this.tabsContent[1].content.push({
            text: `Всего ошибок: ${failed.length}`,
          });
        }

        if (url && Array.isArray(failed) && failed.length !== 0) {
          this.errorFile = url;
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          this.tabsContent[1].content.push({
            text:
              'Произошла ошибка импорта файла. ' +
              'Вероятно, вы использовали неправильные разделители или другие заголовки. ' +
              'Воспользуйтесь шаблоном для импорта файла. ',
          });
        } else {
          this.tabsContent[1].content = [
            {
              text: 'Файл был изменен, прикрепите его заново.',
            },
          ];
          this.file = null;
        }

        throw error;
      }
    },

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return null;

      try {
        this.errors = null;
        await this.prepareResult(
          this.import({
            onlyCreate: this.onlyCreate,
            organizationId: this.organizationId,
            file: this.file,
          }),
        );

        this.$notify({
          group: 'note',
          type: 'info',
          title: 'Файл импортирован.',
          text: '',
        });
        this.$emit('success');
      } catch (error) {
        this.$notify({
          group: 'error',
          type: 'info',
          title: 'Ошибка импорта работников.',
          text: '',
        });
      }
    },
  },
});
</script>

<style lang="scss">
.result-container {
  overflow-x: hidden;
  max-height: 450px;
  border: 1px solid #9e9e9e;
}
</style>
