import { validator } from '@/utils/validation';
import { fileCsv } from '@/utils/validators';
import { required } from 'vuelidate/lib/validators';

export default validator({
  file: {
    name: { required, fileCsv },
  },
  organizationId: { required },
});
